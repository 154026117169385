import React, { ReactElement } from 'react';
import { Step, useStore } from '../../../lib/store';
import Frequency from '../Frequency';
import Amount from '../Amount';
import PaymentProvider from '../PaymentProvider';
import Email from '../Email';
import Details from '../Details';
import Newsletter from '../Newsletter';
import Summary from '../Summary';
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  makeStyles,
  createStyles,
  Grid,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContent: {
      backgroundColor: theme.palette.grey[100],
    },
  }),
);

const Form = (): ReactElement => {
  const classes = useStyles();
  const { state, dispatch } = useStore();

  const handleClick = () => {
    dispatch({ type: 'Show' });
  };

  const handleClose = () => {
    dispatch({ type: 'Reset' });
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClick} size="large" fullWidth>
        Give Gift
      </Button>
      <Dialog fullScreen open={state.currentStep !== Step.Hidden} onClose={handleClose} scroll="paper">
        <DialogTitle>
          <Container maxWidth="sm">
            <Grid alignItems="center" direction="row" container>
              <Grid item style={{ flex: 1 }}>
                Give a Gift to {state.appeal.name}
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose} aria-label="Close">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Container>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <Container maxWidth="sm">
            <Frequency />
            <Amount />
            <PaymentProvider />
            <Email />
            <Details />
            <Newsletter />
            <Summary />
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Form;
