import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { State, Step, StoreProvider } from '../../lib/store';
import Form from './Form';

type DonationProps = {
  initialState: Omit<State, 'currentStep'>;
};

const Donation = ({ initialState }: DonationProps): ReactElement => {
  return (
    <StoreProvider initialState={{ ...initialState, currentStep: Step.Hidden }}>
      <ThemeProvider
        theme={createMuiTheme({
          palette: {
            primary: {
              main: initialState.organization.primaryColor,
            },
          },
          typography: {
            fontFamily: '"Poppins", Helvetica, Arial, sans-serif',
            button: {
              fontWeight: 'bold',
              textTransform: 'none',
            },
          },
        })}
      >
        <Form />
      </ThemeProvider>
    </StoreProvider>
  );
};

export default Donation;
