import React, { ReactElement, useRef, useEffect } from 'react';
import axios from 'axios';
import { Step, useStore } from '../../../lib/store';
import Change from '../Change';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Edit from '../Edit';
import { Button, Grid, TextField } from '@material-ui/core';

const Email = (): ReactElement => {
  const { state, dispatch } = useStore();
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleSubmit, handleChange, handleBlur, values, isValid } = useFormik({
    initialValues: {
      email: state.user.email || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
    }),
    onSubmit: async ({ email }) => {
      try {
        await axios.get('/users/exists', { params: { 'user[email]': email } });
        dispatch({ type: 'SetEmail', email, userExists: true });
      } catch (error) {
        if (error.response.status === 404) {
          dispatch({ type: 'SetEmail', email, userExists: false });
        }
      }
    },
  });

  useEffect((): void => {
    if (state.currentStep === Step.Email && inputRef) {
      inputRef.current.focus();
    }
  }, [state.currentStep]);

  if (state.currentStep === Step.Email) {
    return (
      <Edit title="Enter your email for confirmation" onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            inputRef={inputRef}
            fullWidth
            required
            placeholder="test@example.com"
            inputProps={{ 'aria-label': 'Email' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" disabled={!isValid} variant="contained" color="primary" size="large" fullWidth>
            Next
          </Button>
        </Grid>
      </Edit>
    );
  } else if (state.currentStep > Step.Email && !state.user.id) {
    return <Change step={Step.Email} message={`and get confirmation at ${state.user.email}`} />;
  }
  return null;
};

export default Email;
