import React, { ReactElement, useRef, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { Step, useStore } from '../../../lib/store';
import Change from '../Change';
import Edit from '../Edit';
import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Amount = (): ReactElement => {
  const { state, dispatch } = useStore();
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleSubmit, handleChange, handleBlur, values, isValid } = useFormik({
    initialValues: {
      amount: state.donation.amount !== '0' ? state.donation.amount : '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required()
        .positive()
        .test('maxDigitsAfterDecimal', 'amount field must have 2 digits after decimal or less', (number) =>
          Number.isInteger(number * 10 ** 2),
        ),
    }),
    onSubmit: ({ amount }) => {
      dispatch({ type: 'SetAmount', amount });
    },
  });

  useEffect((): void => {
    if (state.currentStep === Step.Amount && inputRef) {
      inputRef.current.focus();
    }
  }, [state.currentStep]);

  if (state.currentStep === Step.Amount) {
    return (
      <Edit title="How much would you like to give?" onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <TextField
            label="Amount"
            variant="outlined"
            name="amount"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.amount}
            inputRef={inputRef}
            fullWidth
            required
            placeholder="100"
            type="number"
            inputProps={{ 'aria-label': 'Amount' }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" disabled={!isValid} variant="contained" color="primary" size="large" fullWidth>
            Next
          </Button>
        </Grid>
      </Edit>
    );
  } else if (state.currentStep > Step.Amount) {
    return (
      <Change
        step={Step.Amount}
        message={
          <>
            I&apos;d like to give&nbsp;
            <NumberFormat value={state.donation.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </>
        }
      />
    );
  }
  return null;
};

export default Amount;
