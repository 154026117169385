import { Button, Grid, TextField } from '@material-ui/core';
import React, { ReactElement, useRef, useEffect } from 'react';
import { Step, useStore } from '../../../lib/store';
import Change from '../Change';
import Edit from '../Edit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';

const Details = (): ReactElement => {
  const { state, dispatch } = useStore();
  const inputRef = useRef<HTMLInputElement>(null);

  const { handleSubmit, handleChange, handleBlur, values, isValid, setFieldValue } = useFormik({
    initialValues: {
      firstName: state.user.firstName || '',
      lastName: state.user.lastName || '',
      phoneNumber: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      region: '',
      countryCode: '',
      postalCode: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      phoneNumber: Yup.string(),
      addressLine1: Yup.string(),
      addressLine2: Yup.string(),
      city: Yup.string(),
      region: Yup.string(),
      countryCode: Yup.string(),
      postalCode: Yup.string(),
    }),
    onSubmit: async (values) => {
      dispatch({ type: 'SetDetails', values });
    },
  });

  useEffect((): void => {
    if (state.currentStep === Step.Details && inputRef) {
      inputRef.current.focus();
    }
  }, [state.currentStep]);

  if (state.currentStep === Step.Details) {
    return (
      <Edit title="Enter your details" onSubmit={handleSubmit}>
        <Grid item xs={6}>
          <TextField
            label="First Name"
            variant="outlined"
            name="firstName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            inputRef={inputRef}
            fullWidth
            required
            inputProps={{ 'aria-label': 'First Name' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Last Name"
            variant="outlined"
            name="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            fullWidth
            required
            inputProps={{ 'aria-label': 'Last Name' }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone Number"
            variant="outlined"
            name="phoneNumber"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phoneNumber}
            inputProps={{ 'aria-label': 'Phone Number' }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address Line 1"
            variant="outlined"
            name="addressLine1"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.addressLine1}
            inputProps={{ 'aria-label': 'Address Line 1' }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address Line 2"
            variant="outlined"
            name="addressLine2"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.addressLine2}
            inputProps={{ 'aria-label': 'Address Line 2' }}
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            label="City"
            variant="outlined"
            name="city"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            inputProps={{ 'aria-label': 'City' }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Region / State"
            variant="outlined"
            name="region"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.region}
            inputProps={{ 'aria-label': 'Region' }}
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <Autocomplete
            onChange={(_e, value) => setFieldValue('countryCode', value)}
            options={[...Object.keys(state.countries), '']}
            value={values.countryCode}
            getOptionLabel={(option) => state.countries[option] || ''}
            renderInput={(params) => <TextField {...params} label="Country" variant="outlined" fullWidth />}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Postal Code"
            variant="outlined"
            name="postalCode"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.postalCode}
            inputProps={{ 'aria-label': 'Postal Code' }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth type="submit" disabled={!isValid} variant="contained" color="primary" size="large">
            Next
          </Button>
        </Grid>
      </Edit>
    );
  } else if (state.currentStep > Step.Details && !state.userExists) {
    return <Change step={Step.Details} message={`for ${state.user.firstName.trim()} ${state.user.lastName.trim()}`} />;
  }
  return null;
};

export default Details;
