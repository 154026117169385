import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import React, { FormEvent, ReactElement, ReactNode } from 'react';

type ChangeProps = {
  title: string;
  children: ReactNode;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
};

const Edit = ({ title, children, onSubmit: handleSubmit }: ChangeProps): ReactElement => (
  <Card>
    <CardContent>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          {children}
        </Grid>
      </form>
    </CardContent>
  </Card>
);

export default Edit;
