import { Button, Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Step, useStore } from '../../../lib/store';
import Change from '../Change';
import Edit from '../Edit';

const Frequency = (): ReactElement => {
  const { state, dispatch } = useStore();

  const handleClick = (frequency): void => {
    dispatch({ type: 'SetFrequency', frequency });
  };

  const frequencyNameToString = (name: string): string => {
    switch (name) {
      case 'single_gift':
        return 'Just this once';
      case 'weekly':
        return 'Every week';
      case 'every_two_weeks':
        return 'Every two weeks';
      case 'monthly':
        return 'Every month';
      case 'annually':
        return 'Once a year';
    }
  };

  if (state.currentStep === Step.Frequency) {
    return (
      <Edit title="How often?">
        {state.frequencies.map(
          (frequency): ReactElement => (
            <Grid item xs={12} key={frequency.frequency}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={(): void => handleClick(frequency)}
                size="large"
              >
                {frequencyNameToString(frequency.name)}
              </Button>
            </Grid>
          ),
        )}
      </Edit>
    );
  } else if (state.currentStep > Step.Frequency && state.appeal.recurring) {
    return <Change step={Step.Frequency} message={frequencyNameToString(state.donation.frequency.name)} />;
  }
  return null;
};

export default Frequency;
