import React, { ReactElement } from 'react';
import { Step, useStore } from '../../../lib/store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Change from '../Change';
import Edit from '../Edit';
import { Grid, FormControlLabel, Switch, Button, Box } from '@material-ui/core';

const Newsletter = (): ReactElement => {
  const { state, dispatch } = useStore();
  const { handleSubmit, handleChange, values, isValid } = useFormik({
    initialValues: {
      organizationReceiveNewsletter: true,
      appealReceiveNewsletter: true,
    },
    validationSchema: Yup.object({
      organizationReceiveNewsletter: Yup.boolean(),
      appealReceiveNewsletter: Yup.boolean(),
    }),
    onSubmit: ({ organizationReceiveNewsletter, appealReceiveNewsletter }) => {
      dispatch({ type: 'SetNewsletter', organizationReceiveNewsletter, appealReceiveNewsletter });
    },
  });

  if (state.currentStep === Step.Newsletter) {
    return (
      <Edit title="Receive newsletter updates?" onSubmit={handleSubmit}>
        <Grid item xs={12}>
          {!state.userExists && (
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.organizationReceiveNewsletter}
                    onChange={handleChange}
                    name="organizationReceiveNewsletter"
                    color="primary"
                  />
                }
                label={`From ${state.organization.name}`}
              />
            </Box>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={values.appealReceiveNewsletter}
                onChange={handleChange}
                name="appealReceiveNewsletter"
                color="primary"
              />
            }
            label={`From ${state.appeal.name}`}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" disabled={!isValid} variant="contained" color="primary" size="large" fullWidth>
            Next
          </Button>
        </Grid>
      </Edit>
    );
  } else if (state.currentStep > Step.Newsletter) {
    return (
      <Change
        step={Step.Newsletter}
        message={
          <>
            {state.userExists ? (
              <>{state.donation.receiveNewsletter ? 'and receive appeal updates' : 'and receive no updates'}</>
            ) : (
              <>
                {state.user.receiveNewsletter && state.donation.receiveNewsletter && 'and receive all updates'}
                {!state.user.receiveNewsletter && state.donation.receiveNewsletter && 'and receive appeal updates'}
                {state.user.receiveNewsletter &&
                  !state.donation.receiveNewsletter &&
                  'and receive organization updates'}
                {!state.user.receiveNewsletter && !state.donation.receiveNewsletter && 'and receive no updates'}
              </>
            )}
          </>
        }
      />
    );
  }
  return null;
};

export default Newsletter;
