import { Button, Grid, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Step, useStore } from '../../../lib/store';
import Change from '../Change';
import Edit from '../Edit';

const PaymentProvider = (): ReactElement => {
  const { state, dispatch } = useStore();

  const handleClick = (paymentProvider): void => {
    dispatch({ type: 'SetPaymentProvider', paymentProvider });
  };

  const recurring = (): boolean => {
    return parseFloat(state.donation?.frequency?.frequency) > 0;
  };

  if (state.currentStep === Step.PaymentProvider) {
    return (
      <Edit title="How do you want to give?">
        {state.paymentProviders.map((paymentProvider): ReactElement => {
          if (recurring() && !paymentProvider.recurringGift) {
            return null;
          }
          if (!recurring() && !paymentProvider.singleGift) {
            return null;
          }
          return (
            <Grid item xs={12} key={paymentProvider.id}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={(): void => handleClick(paymentProvider)}
                size="large"
              >
                {paymentProvider.name}
              </Button>
              {parseFloat(paymentProvider.percentage) > 0 && parseFloat(paymentProvider.base) > 0 && (
                <Typography style={{ textAlign: 'center' }} variant="body2" color="textSecondary">
                  {new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 1 }).format(
                    parseFloat(paymentProvider.percentage) / 100,
                  )}{' '}
                  +{' '}
                  {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                    parseFloat(paymentProvider.base),
                  )}{' '}
                  convenience fee
                </Typography>
              )}
            </Grid>
          );
        })}
      </Edit>
    );
  } else if (state.currentStep > Step.PaymentProvider) {
    return <Change step={Step.PaymentProvider} message={`using ${state.donation.paymentProvider.name}`} />;
  }
  return null;
};

export default PaymentProvider;
