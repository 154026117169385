import { IconButton, Grid, makeStyles, createStyles, Card, CardContent, Typography } from '@material-ui/core';
import React, { ReactElement, ReactNode } from 'react';
import { Step, useStore } from '../../../lib/store';
import EditIcon from '@material-ui/icons/Edit';
const useStyles = makeStyles((theme) =>
  createStyles({
    gridItem: {
      flex: 1,
    },
    card: {
      marginBottom: theme.spacing(2),
    },
    cardContent: {
      '&:last-child': {
        padding: theme.spacing(2),
      },
    },
  }),
);

type ChangeProps = {
  step: Exclude<Step, Step.Hidden>;
  message: string | ReactNode;
};

const Change = ({ step, message }: ChangeProps): ReactElement => {
  const classes = useStyles();
  const { dispatch } = useStore();

  const handleClick = (): void => {
    dispatch({ type: 'SetStep', step });
  };

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2} alignItems="center">
          <Grid item className={classes.gridItem}>
            <Typography variant="h6">{message}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClick}>
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Change;
