import { Box, Button, Typography, Link, FormControlLabel, Checkbox } from '@material-ui/core';
import axios from 'axios';
import React, { ReactElement, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { Step, useStore } from '../../../lib/store';

const Summary = (): ReactElement => {
  const { state } = useStore();
  const [terms, setTerms] = useState(true);
  const [fee, setFee] = useState(0);
  const [total, setTotal] = useState(0);

  const calculateFee = (): void => {
    const percentage = parseFloat(state.donation.paymentProvider.percentage) / 100;
    const base = parseFloat(state.donation.paymentProvider.base);
    const amount = parseFloat(state.donation.amount);
    const fee = Math.ceil(((amount + base) / (1 - percentage) - amount) * 100) / 100;
    setTotal(amount + fee);
    setFee(fee);
  };

  const handleClick = async (): Promise<void> => {
    const params = {
      donation: {
        amount: state.donation.amount,
        frequency: state.donation.frequency?.frequency,
        payment_provider_id: state.donation.paymentProvider.id,
        receive_newsletter: state.donation.receiveNewsletter,
      },
      user: {
        first_name: state.user.firstName?.trim(),
        last_name: state.user.lastName?.trim(),
        email: state.user.email,
        receive_newsletter: state.user.receiveNewsletter,
        phone_number: state.user.phoneNumber,
        address_attributes: {
          address_line_1: state.user.addressLine1,
          address_line_2: state.user.addressLine2,
          city: state.user.city,
          region: state.user.region,
          country_code: state.user.countryCode,
          postal_code: state.user.postalCode,
        },
      },
      authenticity_token: state.authenticityToken,
    };
    const response = await axios.post(`/appeals/${state.appeal.id}/donations`, params);
    window.location = response.data.redirect_url;
  };

  useEffect((): void => {
    if (state.donation.paymentProvider) {
      calculateFee();
    }
  }, [state.donation.amount, state.donation.paymentProvider]);

  if (state.currentStep === Step.Summary) {
    return (
      <Box>
        {fee > 0 && (
          <Box data-testid="summary-fee">
            <Typography variant="h5">
              For a total of&nbsp;
              <NumberFormat
                value={total}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Typography>
            <Typography>
              Includes&nbsp;
              <NumberFormat
                value={fee}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
              &nbsp;convenience fee
            </Typography>
          </Box>
        )}
        <Box my={2}>
          {!state.userExists && (
            <Typography gutterBottom data-testid="summary-sign-up">
              <Link href={state.links.webUrl}>{state.organization.name}</Link> uses{' '}
              <Link href={state.links.rootUrl}>fundableapp.com</Link> to make giving easy!
              <br />
              <FormControlLabel
                control={
                  <Checkbox checked={terms} onChange={() => setTerms((terms) => !terms)} name="terms" color="primary" />
                }
                label={
                  <>
                    I agree with Fundable&apos;s <Link href={state.links.privacyUrl}>Privacy Policy</Link>
                    {state.links.refundPolicyUrl && (
                      <>
                        {' '}
                        and <Link href={state.links.refundPolicyUrl}>Refund Policy</Link>
                      </>
                    )}
                    .
                  </>
                }
              />
            </Typography>
          )}
          <Button variant="contained" color="primary" fullWidth onClick={handleClick} size="large" disabled={!terms}>
            Give Now
          </Button>
        </Box>
        <Typography align="center" color="textSecondary" variant="body2">
          You will be taken to an external provider to complete your gift.
          <br />
          We never collect your banking details directly. Instead, we rely on secure payment processors.
        </Typography>
      </Box>
    );
  }
  return null;
};

export default Summary;
